import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter, useLocation } from 'react-router-dom'

const { Fragment, Suspense, lazy } = React
const AdminArea = lazy(() => import('./admin/admin.js'))
const ClientArea = lazy(() => import('./front/front.js'))

var AreaSelector = () => {
  const location = useLocation()
  let isAdmin = !!location.pathname.match(/^\/admin/)

  document.body.classList.remove('front-page', 'admin-page')
  document.body.classList.add(isAdmin ? 'admin-page' : 'front-page')

  return (
    <Suspense fallback={<Fragment />}>
      {isAdmin && <AdminArea />}
      {!isAdmin && <ClientArea />}
    </Suspense>
  )
}

const root = createRoot(document.getElementById('root'))

root.render(
  <HashRouter>
    <AreaSelector />
  </HashRouter>
)
